import pkceChallenge from 'pkce-challenge';
import Config from '../Config';

const AUTH_SET_ACTION = 'AUTH_SET';
const AUTH_CLEAR_ACTION = 'AUTH_CLEAR';
const AUTH_ERROR_ACTION = 'AUTH_ERROR';

function getAppClientId() {
  let appClientId = sessionStorage.getItem('appClientId');
  console.log('App Client ID retrieved from session storage:', appClientId);
  return appClientId;
}

export function authDefaultState() {
  console.log('Setting default authentication state.');
  return {
    authorizing: false,
    accessToken: null,
    accessTokenJwtString: null,
    idToken: null,
    idTokenJwtString: null,
    state: null,
    error: null,
    showAuthConfirm: false,
    permission: {},
    currentLanguage: null
  };
}

function hasAccessRole(accessToken, role) {
  console.log('Checking access role:', role);
  if (!accessToken) {
    console.log('Access token is missing.');
    return false;
  }

  const userRoles = accessToken['cognito:groups'] || [];
  console.log('User roles:', userRoles);
  return userRoles.includes(role);
}

async function validateAuthTokens(idToken, accessToken) {
  try {
    console.log('Validating auth tokens.');
    const response = await fetch(`${Config.apiGateway.URL}/validate`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idToken,
        accessToken
      })
    });

    console.log('Validation response status:', response.status);
    return response;
  } catch (error) {
    console.error('Error during token validation:', error);
    throw error;
  }
}

export function authorize(dispatch, appClientId=undefined) {
  console.log('Initiating authorization process.', appClientId);
  dispatch({
    type: AUTH_SET_ACTION,
    payload: {
      authorizing: true
    }
  });

  const state = Date.now();
  const pkce = pkceChallenge(128);

  console.log('Generated state and PKCE challenge:', state, pkce);

  sessionStorage.setItem('state', state);
  sessionStorage.setItem('codeVerifier', pkce.code_verifier);
  sessionStorage.setItem('lastPath', window.location.pathname);

  const clientId = appClientId || getAppClientId();
  console.log('client id :--- ', clientId)
  // setTimeout(()=>{
  window.location.assign(
      `${Config.forwoodId.URL}/oauth2/authorize?response_type=code&scope=openid&client_id=${clientId}&redirect_uri=${Config.reactApp.HOSTNAME}&state=${state}&code_challenge=${pkce.code_challenge}`
    );
    // },10000)
}

async function token(dispatch, payload) {
  try {
    console.log('Requesting token with payload:', payload);
    const result = await fetch(
      `${Config.forwoodId.URL}/oauth2/token`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    );

    const data = await result.json();
    console.log('Token response status:', result.status);

    if (!result.ok) {
      console.error('Token request failed with error:', data.errors);
      if (result.status === 401) {
        console.warn('Unauthorized. Showing auth confirmation.');
        sessionStorage.setItem('lastPath', window.location.pathname);
        dispatch({
          type: AUTH_SET_ACTION,
          payload: {
            showAuthConfirm: true
          }
        });
        return null;
      }

      throw new Error(data.errors);
    }

    console.log('Token request succeeded:', data);
    return data;
  } catch (error) {
    console.error('Error during token request:', error);
    throw error;
  }
}

export async function getTokens(dispatch, code) {
  try {
    console.log('Exchanging code for tokens:', code);
    const appClientId = getAppClientId();

    const payload = await token(dispatch, {
      grant_type: 'authorization_code',
      code,
      client_id: appClientId,
      redirect_uri: Config.reactApp.HOSTNAME,
      code_verifier: sessionStorage.getItem('codeVerifier')
    });

    if (!payload) {
      console.warn('No tokens received.');
      return null;
    }

    console.log('Tokens received:', payload);
    return {
      idToken: payload.id_token,
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token
    };
  } catch (error) {
    console.error('Error during token exchange:', error);
    return null;
  }
}

export async function refreshTokens(dispatch, refreshToken) {
  try {
    console.log('Refreshing tokens with refresh token:', refreshToken);
    const appClientId = getAppClientId();

    const payload = await token(dispatch, {
      grant_type: 'refresh_token',
      client_id: appClientId,
      refresh_token: refreshToken
    });

    if (!payload) {
      console.warn('No tokens received during refresh.');
      return null;
    }

    console.log('Tokens refreshed:', payload);
    return {
      idToken: payload.id_token,
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token
    };
  } catch (error) {
    console.error('Error during token refresh:', error);
    return null;
  }
}

function parseJwt(jwtToken) {
  try {
    console.log('Parsing JWT token.');
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
    return JSON.parse(payload);
  } catch (err) {
    console.error('Error parsing JWT:', err);
    return null;
  }
}

export function hasExpired(jwtToken) {
  console.log('Checking if JWT has expired.');
  const payload = parseJwt(jwtToken);
  if (!payload) {
    console.warn('Invalid JWT payload.');
    return true;
  }

  const isExpired = (Date.now() / 1000) > payload.exp;
  console.log('JWT expiration status:', isExpired);
  return isExpired;
}

export function clearAuth(dispatch) {
  console.log('Clearing authentication state.');
  dispatch({
    type: AUTH_CLEAR_ACTION
  });

  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

export function authError(dispatch, payload) {
  console.error('Authentication error:', payload);
  clearAuth(dispatch);
  dispatch({
    type: AUTH_ERROR_ACTION,
    payload
  });
}

export async function setAuth(dispatch, payload) {
  try {
    console.log('Setting authentication with payload:', payload);
    const response = await validateAuthTokens(payload.idToken, payload.accessToken);

    if (!response.ok) {
      const data = await response.json();
      return authError(dispatch, {
        status: response.status,
        message: data.error.message
      });
    }

    const accessToken = parseJwt(payload.accessToken);

    dispatch({
      type: AUTH_SET_ACTION,
      payload: {
        accessToken,
        accessTokenJwtString: payload.accessToken,
        idToken: parseJwt(payload.idToken),
        idTokenJwtString: payload.idToken,
        refreshTokenJwtString: payload.refreshToken,
        currentLanguage: localStorage.getItem('i18nextLng'),
        permission: {
          teams: {
            access: hasAccessRole(accessToken, Config.reactApp.TEAMS_ACCESS_ROLE),
          },
          teamTargets: {
            access: hasAccessRole(accessToken, Config.reactApp.TEAM_TARGETS_ACCESS_ROLE),
          },
        },
      }
    });

    console.log('Authentication state updated.');

    localStorage.setItem('accessToken', payload.accessToken);
    localStorage.setItem('idToken', payload.idToken);
    localStorage.setItem('refreshToken', payload.refreshToken);

    return true;
  } catch (error) {
    console.error('Error setting authentication:', error);
    return false;
  }
}

export async function getIdToken(dispatch) {
  try {
    console.log('Getting ID token from local storage.');
    const idToken = localStorage.getItem('idToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!idToken || !refreshToken) {
      console.warn('ID token or refresh token is missing.');
      return authError(dispatch, {
        status: 401,
        message: 'Invalid session'
      });
    }

    if (!hasExpired(idToken)) {
      console.log('ID token is valid.');
      return idToken;
    }

    console.log('ID token has expired. Refreshing tokens.');
    const result = await refreshTokens(dispatch, refreshToken);
    if (!result) {
      console.warn('Failed to refresh tokens.');
      return null;
    }

    console.log('Tokens refreshed successfully.');
    await setAuth(dispatch, result);
    return result.idToken;
  } catch (error) {
    console.error('Error getting ID token:', error);
    return null;
  }
}

export async function handleAuthSession(dispatch) {
  try {
    console.log('Handling authentication session.');
    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!idToken || !refreshToken) {
      console.warn('ID token or refresh token is missing. Redirecting to authorize.');
      return authorize(dispatch);
    }

    if (hasExpired(idToken)) {
      console.log('ID token has expired. Refreshing tokens.');
      const tokens = await refreshTokens(dispatch, refreshToken);
      if (!tokens) {
        console.warn('Failed to refresh tokens.');
        return null;
      }

      console.log('Tokens refreshed. Updating authentication state.');
      return setAuth(dispatch, tokens);
    }

    console.log('ID token is valid. Setting authentication state.');
    await setAuth(dispatch, {
      idToken,
      accessToken,
      refreshToken
    });

    return true;
  } catch (error) {
    console.error('Error handling authentication session:', error);
    return null;
  }
}

export function logout(dispatch) {
  console.log('Logging out user.');
  clearAuth(dispatch);
  window.location.assign(`${Config.forwoodId.URL}/logout`);
}

export async function exchangeCodeForTokens(dispatch, code, state) {
  try {
    console.log('Exchanging code for tokens with code:', code, 'and state:', state);

    if (sessionStorage.getItem('state') !== state) {
      console.warn('Invalid auth state detected.');
      authError(dispatch, {
        status: 401,
        message: 'Invalid auth state'
      });

      return;
    }

    const tokens = await getTokens(dispatch, code);
    if (!tokens) {
      console.warn('Failed to retrieve tokens using authorization code.');
      return;
    }

    console.log('Tokens retrieved successfully. Setting authentication state.');
    await setAuth(dispatch, tokens);
  } catch (error) {
    console.error('Error exchanging code for tokens:', error);
  }
}

export default (state = authDefaultState(), action) => {
  const { payload } = action;

  console.log('Reducer action received:', action.type);
  switch (action.type) {
    case AUTH_SET_ACTION: {
      console.log('Updating authentication state with payload:', payload);
      return {
        ...state,
        ...payload
      };
    }

    case AUTH_CLEAR_ACTION:
      console.log('Clearing authentication state.');
      return {
        ...authDefaultState()
      };

    case AUTH_ERROR_ACTION:
      console.error('Authentication error action received:', payload);
      return {
        ...state,
        error: {
          ...payload
        }
      };

    default:
      console.log('Unhandled action type:', action.type);
      return state;
  }
};
